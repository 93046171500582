import { mobile, notMobile, styled, useRemToPx } from '@obvio/app'
import { CarouselKeen, Section } from '@obvio/ui'

import type { CarouselKeenProps } from '@obvio/ui'
import type { KeenSliderPlugin } from 'keen-slider/react'
import type { ReactElement } from 'react'

export const CarouselDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(theme) => theme.stroke};
`

export const CarouselImage = styled.div`
  width: 100%;
  height: 33.75rem;
  position: relative;

  @media ${mobile} {
    height: 9rem;
  }
`

export const CarouselSection = styled(Section)`
  width: 100vw;
  max-width: unset !important;

  .keen-slider > div {
    min-width: 25.875rem;
  }

  .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
    width: unset !important;
  }

  @media ${mobile} {
    width: 100%;
  }
`

const innerPlugins: Record<
  'imgAutoHeight' | 'autoWidth',
  (...args: any[]) => KeenSliderPlugin
> = {
  autoWidth:
    (widths: number[]) =>
    ({ slides, update }) => {
      slides.forEach((slide, index) => {
        const width = widths[index]
        // eslint-disable-next-line no-param-reassign
        slide.style.minWidth = `${width}px`

        // eslint-disable-next-line no-param-reassign
        slide.style.maxWidth = `${width}px`
      })

      update()
    },
  imgAutoHeight:
    () =>
    // eslint-disable-next-line unicorn/consistent-function-scoping
    ({ slides, on }) => {
      function setImgHeights() {
        const heights = slides?.map((slide) => {
          const [imgWrap] = slide.children[0].children
          return imgWrap.clientHeight
        })
        const minHeight = Math.min(...heights)

        slides.forEach((slide) => {
          const [imgWrap] = slide.children[0].children
          ;(imgWrap as HTMLDivElement).style.height = `${minHeight}px`
        })
      }

      on('created', setImgHeights)
      on('optionsChanged', setImgHeights)
    },
}

type CarouselBaseProps<DataType> = {
  data: DataType[]
  options?: CarouselKeenProps<DataType>['options']
  plugins?: KeenSliderPlugin[]
  render(data: DataType): ReactElement
}

const defaultOptions = {}

export function CarouselBase<DataType>({
  data,
  options = defaultOptions,
  plugins,
  render,
}: CarouselBaseProps<DataType>): ReactElement {
  const remToPx = useRemToPx()

  return (
    <CarouselKeen
      data={data}
      plugins={plugins}
      render={render}
      hideArrows
      options={{
        defaultAnimation: {
          duration: 500,
        },
        loop: true,
        drag: false,
        breakpoints: {
          [mobile]: {
            slides: {
              perView: 1,
              spacing: remToPx(2),
            },
          },
          [notMobile]: {
            slides: {
              perView: 'auto',
              spacing: remToPx(2),
            },
          },
        },
        ...options,
      }}
    />
  )
}

CarouselBase.plugins = innerPlugins
