import {
  Context,
  Link,
  notDesktop,
  styled,
  Text,
  useParamModal,
} from '@obvio/app'
import { SvgCloseMenu } from '@obvio/svg'
import { useUi } from '@obvio/template'
import { Button, Drawer, Stack } from '@obvio/ui'
import { DrawerContent } from '@obvio/ui/Drawer/components'
import { motion } from 'framer-motion'
import { useCallback } from 'react'

import { ContactModal } from './Modals/ContactModal'

import type { ReactElement } from 'react'

const DrawerPanel = styled(Drawer)`
  > div > div {
    background: #68623599;
  }
  section > div {
    max-width: 50vw;
    width: 50vw;
    > div > div {
      background: #d6ce95;
    }
  }
  min-height: 100svh;
  @media ${notDesktop} {
    section > div {
      max-width: 90vw;
      width: 100vw;
    }
  }
`

const Content = styled(DrawerContent)`
  padding: ${(theme) => theme.spacing.large};
`

const CloseButton = styled(Button)`
  position: absolute;
  top: ${(theme) => theme.spacing.medium};
  right: ${(theme) => theme.spacing.medium};
`

const PatternWrap = styled.div`
  width: 100%;
  height: 11rem;
  overflow: hidden;
`
const Pattern = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/static/images/pattern.png');
  background-repeat: repeat;
  background-size: contain;
  transform: scale(3.5);
`

const Divider = styled.div`
  max-width: 22rem;
  height: 1px;
  background: #686235;
  margin: ${(theme) => theme.spacing.large} 0;
`

const MotionLink = motion(Link)

const ContactStack = styled(Stack)`
  max-width: 22rem;
`

export function Panel(): ReactElement | null {
  const [{ menuPanelOpen }, dispatch] = useUi('menuPanelOpen')
  const { show } = useParamModal(ContactModal.param)

  const close = useCallback(() => dispatch('CLOSE_ALL_PANELS'), [dispatch])

  const MenuLink = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types -- Again this is a bug in eslint prob
    ({ href, children }: { href: string; children: string }) => (
      <MotionLink href={href} onClick={close} whileHover={{ x: 10 }}>
        {children}
      </MotionLink>
    ),
    [close],
  )

  if (!menuPanelOpen) {
    return null
  }

  return (
    <Context name="menu">
      <DrawerPanel handleClickOutside={close}>
        <>
          <Content>
            <Stack kind="vertical" spacing="0.2rem">
              <MenuLink href="/#terroir">Terroir</MenuLink>
              <MenuLink href="/#wina">Nasze wina</MenuLink>
              <MenuLink href="/#winifikacja">Winifikacja</MenuLink>
              <MenuLink href="/#atrakcje">Atrakcje</MenuLink>
              <MenuLink href="/#aktualnosci">Aktualności</MenuLink>
              <MenuLink href="/#sprzedaz">Sprzedaż hurtowa i HORECA</MenuLink>
              <MenuLink href="/enoturystyka">Enoturystyka</MenuLink>
              <MenuLink href="https://www.poziom511.pl/sklep/kategoria/wina-511">
                Sklep
              </MenuLink>
              <MotionLink
                whileHover={{ x: 10 }}
                onClick={() => {
                  close()
                  void show('true')
                }}
              >
                Kontakt
              </MotionLink>
            </Stack>
            <Divider />
            <ContactStack kind="vertical" spacing="medium">
              <Text tag="span" as="sub-h2">
                Wine tour z degustacją
              </Text>
              <Text>
                Zorganizujemy dla ciebie dedykowany wine tour z degustacją,
                spacerem po winnicy i innymi atrakcjami.
              </Text>
              <div>
                <Button
                  onClick={() => {
                    close()
                    void show('true')
                  }}
                >
                  ZORGANIZUJ WINE TOUR
                </Button>
              </div>
            </ContactStack>
            <CloseButton kind={['icon', 'transparent']} onClick={close}>
              <SvgCloseMenu />
            </CloseButton>
          </Content>
          <PatternWrap>
            <Pattern />
          </PatternWrap>
        </>
      </DrawerPanel>
    </Context>
  )
}
