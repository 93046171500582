import { Image, notDesktop, styled, Text } from '@obvio/app'
import { RichText } from '@obvio/template'
import { Grid } from '@obvio/ui'

import { Accordion } from './Accordion'
import { useStrains } from '@/utils/hooks/useStrains'

import type { ImageAsset } from '@obvio/app'
import type { ReactElement } from 'react'

const ImageWrap = styled.div`
  padding: 0 ${(theme) => theme.spacing.large};
`

const GridMedia = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`

type StrainsAccordionProps = {
  image: ImageAsset
}

export function StrainsAccordion({
  image,
}: StrainsAccordionProps): ReactElement {
  const { data } = useStrains()

  return (
    <GridMedia templateColumns="1fr 1fr" gap="large">
      <div>
        <Accordion>
          {(data ?? []).map((strain) => (
            <Accordion.Element
              key={strain.id}
              title={
                <Text tag="span" as="sub-h3">
                  {strain.title}
                </Text>
              }
            >
              <RichText value={strain.description} />
            </Accordion.Element>
          ))}
        </Accordion>
      </div>
      <ImageWrap>
        <Image img={image} />
      </ImageWrap>
    </GridMedia>
  )
}
