export const MAP_STYLE = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#f2e8de',
      },
      {
        lightness: '39',
      },
      {
        gamma: '1.01',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#e7e1d6',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#d9d9d9',
      },
    ],
  },
]

export const MAP_MARKERS = [
  {
    id: 'poziom',
    title: 'POZIOM 511 Jura Wellness Hotel & SPA',
    position: {
      lat: 50.450_806_063_574_476,
      lng: 19.553_159_221_429_052,
    },
    description:
      'https://www.google.com/maps/dir//Poziom+511+Jura+Wellness+Hotel+%26+Spa,+Boner%C3%B3w+33,+42-440+Podzamcze/@50.4506363,19.5532256,19.35z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47172261ae54d525:0x4808fdba28507dc2!2m2!1d19.5530204!2d50.4507713',
  },
  {
    id: 'biale-skaly',
    title: 'Winnica Białe Skały',
    position: {
      lat: 50.450_269,
      lng: 19.554_963,
    },
    description:
      'https://www.google.com/maps/dir//50.450269,19.554963/@50.45027,19.554236,326m/data=!3m1!1e3',
  },
]
