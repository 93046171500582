import { mobile, notMobile, styled, useRemToPx, Image } from '@obvio/app'
import { FadeIn } from '@obvio/template'
import { Stack } from '@obvio/ui'
import { useCallback, useMemo } from 'react'

import { CarouselBase, CarouselSection } from './CarouselBase'

import type { ImageAsset } from '@obvio/app'
import type { ReactElement } from 'react'

const ImageWrap = styled(Stack)`
  height: 35rem;
  width: 100%;
  color: ${(theme) => theme.colors.secondary};
  text-align: left;
  @media ${mobile} {
    height: 25rem;
  }
`

type ImageCompProps = {
  image: ImageAsset
}

function ImageComp({ image }: ImageCompProps) {
  return (
    <ImageWrap>
      <Image img={image} />
    </ImageWrap>
  )
}

type ImagesCarouselProps = {
  images: ImageAsset[]
}

const defaultImages: ImageAsset[] = []

export function ImagesCarousel({
  images = defaultImages,
}: ImagesCarouselProps): ReactElement {
  const remToPx = useRemToPx()

  const MemoizedImage = useCallback(
    (image: ImageAsset) => <ImageComp image={image} />,
    [],
  )

  const widths = useMemo(
    () =>
      images.map((image: { ratio?: number }) => {
        const ratio = image.ratio ?? 1
        return remToPx(35) * ratio
      }),
    [images, remToPx],
  )
  return (
    <CarouselSection>
      <FadeIn>
        <CarouselBase
          data={images}
          render={MemoizedImage}
          plugins={[CarouselBase.plugins.autoWidth(widths)]}
          options={{
            defaultAnimation: {
              duration: 500,
            },
            autoSwitchTimeout: 2500,

            breakpoints: {
              [notMobile]: {
                loop: true,
                slides: {
                  perView: 'auto',
                  spacing: remToPx(2),
                  origin: 'center',
                },
              },
              [mobile]: {
                loop: true,
                slides: {
                  perView: 1,
                  spacing: remToPx(2),
                },
              },
            },
          }}
        />
      </FadeIn>
    </CarouselSection>
  )
}
