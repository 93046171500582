import { useParamModal, Text, styled, useMutation } from '@obvio/app'
import { SvgCloseMenu } from '@obvio/svg'
import {
  Button,
  CheckboxInput,
  Form,
  FormPage,
  Stack,
  Submit,
  SubmitState,
  TextInput,
} from '@obvio/ui'

import { StyledModal } from './components'
import { Nav } from '../Nav'
import { useEvents } from '@/utils/hooks/useEvents'

import type { ReactElement } from 'react'

const FormActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const TimeText = styled(Text)`
  text-transform: uppercase;
`

const StyledNav = styled(Nav)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  > nav {
    background: #d6ce95;
  }
`

const PatternWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 6rem;
  overflow: hidden;
`
const Pattern = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/static/images/pattern.png');
  background-repeat: repeat;
  background-size: contain;
  transform: scale(4.5);
`

const formater = new Intl.DateTimeFormat('pl-PL', {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
})

const MODAL_PARAM = 'event-modal'

export function EventModal(): ReactElement | null {
  const { data, hide } = useParamModal(MODAL_PARAM)
  const { data: eventsData } = useEvents()

  const [register] = useMutation('registerGuest')

  const selectedEvent = eventsData?.find((event) => event.slug === data)

  const handleSubmit = async (formData: any) => {
    if (!selectedEvent) {
      return SubmitState.OK
    }

    await register({
      eventId: selectedEvent.id,
      ...formData,
    })
  }

  if (!selectedEvent) {
    return null
  }

  return (
    <StyledModal open={Boolean(data)}>
      <Stack kind="vertical" fullWidth>
        <Form contextOnly onSubmit={handleSubmit}>
          <FormPage>
            <Text tag="h1">
              Zapisujesz się na:
              <br />
              {selectedEvent.title}
            </Text>
            <TimeText tag="span" as="sub-h1">
              {formater.format(selectedEvent.date)}
            </TimeText>
            <TextInput placeholder="IMIĘ I NAZWISKO" name="name" />
            <TextInput placeholder="NUMER TELEFONU" name="phone" />
            <TextInput placeholder="EMAIL" name="email" />
            <FormActions>
              <CheckboxInput
                label="Akceptuję politykę prywatności"
                name="rodo"
                noSubmit
                optional={false}
              />
              <Submit>Wyślij</Submit>
            </FormActions>
          </FormPage>
          <FormPage successPage>Dziękujemy za zapis!</FormPage>
        </Form>
      </Stack>
      <StyledNav
        customIcon={
          <Button kind={['icon', 'transparent']} onClick={hide}>
            <SvgCloseMenu />
          </Button>
        }
      />
      <PatternWrap>
        <Pattern />
      </PatternWrap>
    </StyledModal>
  )
}

EventModal.param = MODAL_PARAM
