import { css, Global, styled } from '@obvio/app'
import { ObvioFooter } from '@obvio/ui'

import { Footer } from './Footer'
import { ContactModal } from './Modals/ContactModal'
import { EventModal } from './Modals/EventModal'
import { WineModal } from './Modals/WineModal'
import { Nav } from './Nav'
import { Panel } from './Panel'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const Main = styled.main`
  > section:nth-of-type(-n + 11):nth-of-type(n + 6) {
    background-color: ${(theme) => theme.colors.additional[2]};
    button {
      border-color: ${(theme) => theme.colors.additional[2]};
    }
  }

  > section:nth-of-type(-n + 21):nth-of-type(n + 14) {
    background-color: ${(theme) => theme.colors.primary};
    button,
    a {
      border-color: ${(theme) => theme.colors.primary.deep};
    }
    * {
      color: ${(theme) => theme.colors.primary.deep} !important;
    }
  }
`

const StyledObvioFooter = styled(ObvioFooter)`
  background-color: #d6ce95;
  border-top: 1px solid white;
  > a {
    color: #686235;
  }
`

const globalStyle = css`
  body {
    background-color: ${(theme) => theme.background};
  }
  html {
    scroll-behavior: smooth;
  }
  ${(theme) => theme.global}
`

type LayoutProps = AllowUndefinedForOptional<{
  children: ReactElement | ReactElement[]
}>

export function Layout({ children }: LayoutProps): ReactElement {
  return (
    <>
      <Nav />
      <Main>{children}</Main>
      <Footer />
      <StyledObvioFooter />
      <WineModal />
      <EventModal />
      <ContactModal />
      <Panel />
      <Global styles={globalStyle} />
    </>
  )
}
