import {
  AssetsProvider,
  Image,
  notDesktop,
  styled,
  Text,
  useParamModal,
} from '@obvio/app'
import { SvgArrow } from '@obvio/svg'
import { RichText } from '@obvio/template'
import { Button, Grid, Stack } from '@obvio/ui'
import { useKeenSlider } from 'keen-slider/react'
import { useState } from 'react'
import { useMemo } from 'react'

import { Arrow } from './Arrow'
import { EventModal } from './Modals/EventModal'
import { ArrowButton, NavigationWrap } from './Modals/WineModal/components'
import { useEvents } from '@/utils/hooks/useEvents'

import type { BasicShades, Color } from '@obvio/utils'
import type { ReactElement } from 'react'

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media ${notDesktop} {
    > span {
      display: none;
    }
  }
`

const Wrap = styled(Grid)`
  min-height: 43rem;
  overflow: hidden;
  > div:first-of-type > div:first-of-type {
    width: 80%;
  }
  > span {
    display: none;
  }
  @media ${notDesktop} {
    > span {
      display: block;
      margin-bottom: 0;
    }
    height: auto;
    > div:first-of-type > div:first-of-type {
      width: 100%;
    }
    > div:nth-of-type(2) {
      height: 25rem;
    }
    grid-template-columns: minmax(0, 1fr);
    > div:first-of-type {
      grid-row: 4;
    }
  }
`

const ButtonArrow = styled(Button)<{ $rotated?: boolean | undefined }>`
  border: 1px solid
    ${(theme) => (theme.colors.additional[2] as Color<BasicShades>).deep};
  border-radius: 9999px !important;
  min-width: 0;
  width: 4.5rem;
  height: 4.5rem;
  padding: 0;
  transform: ${(_, { $rotated }) =>
    $rotated ? 'rotate(180deg)' : 'rotate(0deg)'};
  :hover {
    background: white;
    border: 1px solid
      ${(theme) => (theme.colors.additional[2] as Color<BasicShades>).deep};
  }
`

const GlobalTitle = styled(Text)`
  margin-bottom: ${(theme) => theme.spacing.large};
`

const BottomPanel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  @media ${notDesktop} {
    display: none;
  }
`

const Progress = styled(Stack)`
  height: 1rem;
`

export function EventsCarousel(): ReactElement | null {
  const { data } = useEvents()
  const { show } = useParamModal(EventModal.param)
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [leftSliderRef, leftInstanceRef] = useKeenSlider<HTMLDivElement>({
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    drag: false,
  })
  const [rightSliderRef, rightInstanceRef] = useKeenSlider<HTMLDivElement>({
    drag: false,
  })

  const prev = () => {
    leftInstanceRef.current?.prev()
    rightInstanceRef.current?.prev()
  }

  const next = () => {
    leftInstanceRef.current?.next()
    rightInstanceRef.current?.next()
  }

  const title = useMemo(
    () => (
      <GlobalTitle tag="span" as="sub-h1">
        AKTUALNIE W NASZEJ WINNICY
      </GlobalTitle>
    ),
    [],
  )

  if (!data || data.length === 0) {
    return null
  }

  return (
    <Wrap templateColumns="50% 50%" gap="large">
      {title}
      <Panel>
        {title}
        <div ref={leftSliderRef} className="keen-slider" style={{ flex: 1 }}>
          {data.map((item) => (
            <div key={item.title} className="keen-slider__slide">
              <Stack kind="vertical" spacing="large">
                <Text tag="h3" as="h1">
                  {item.title}
                </Text>
                <div>
                  <RichText value={item.description} />
                </div>
                <div>
                  <Button onClick={() => show(item.slug)}>ZAPISZ SIĘ</Button>
                </div>
              </Stack>
            </div>
          ))}
        </div>
        <BottomPanel>
          <Stack>
            <ButtonArrow kind="icon" onClick={prev}>
              <SvgArrow />
            </ButtonArrow>
            <ButtonArrow kind="icon" onClick={next} $rotated>
              <SvgArrow />
            </ButtonArrow>
          </Stack>
          <Progress divider>
            <Text tag="span">{currentSlide + 1}</Text>
            <Text tag="span">{data.length}</Text>
          </Progress>
        </BottomPanel>
      </Panel>
      <Panel>
        <div
          ref={rightSliderRef}
          className="keen-slider"
          style={{ height: '100%' }}
        >
          {data.map((item) => (
            <div key={item.title} className="keen-slider__slide">
              <AssetsProvider path={`/events/${item.id}`}>
                <Image img={item.images[0]} />
              </AssetsProvider>
            </div>
          ))}
        </div>
      </Panel>
      <NavigationWrap>
        <Text tag="span">
          {currentSlide + 1} / {data.length}
        </Text>
        <Stack>
          <ArrowButton kind="icon" onClick={prev}>
            <Arrow direction="left" length="3rem" />
          </ArrowButton>
          <ArrowButton kind="icon" onClick={next}>
            <Arrow direction="right" length="3rem" />
          </ArrowButton>
        </Stack>
      </NavigationWrap>
    </Wrap>
  )
}
