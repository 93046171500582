import { notDesktop, styled } from '@obvio/app'
import { Modal } from '@obvio/ui'

export const StyledModal = styled(Modal)<{ $declined?: boolean }>`
  background-color: ${(_, { $declined }) =>
    $declined ? '#686235' : '#d6ce95'};
  * {
    color: ${(theme, { $declined }) =>
      $declined ? theme.colors.additional[2] : '#686235'} !important;
  }
  button:hover {
    background: transparent;
    text-decoration: underline;
  }
  section {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    p {
      max-width: 30rem;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    > div > div:last-of-type {
      justify-content: center;
    }
    @media ${notDesktop} {
      width: 80%;
    }
  }
`
