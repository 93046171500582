import {
  css,
  notDesktop,
  styled,
  Text,
  useParamModal,
  Image,
  AssetsProvider,
} from '@obvio/app'
import { FadeIn } from '@obvio/template'
import { Button, Stack } from '@obvio/ui'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'

import { WineModal } from './Modals/WineModal'
import { useWines } from '@/utils/hooks/useWines'

import type { WineData } from '@/utils/hooks/useWines'
import type { StateSetter } from '@obvio/utils'
import type { ReactElement } from 'react'

const Wrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  > span {
    margin-bottom: 3rem;
  }
`

const WineTitle = styled(Text)`
  font-size: 5.625rem;
  line-height: 100%;
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
`

const WineSection = styled.section<{ $color: string }>`
  background-color: ${(_, { $color }) => $color} !important;
  color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.25s;
  > div {
    padding: 2rem 0;
    overflow: unset;
  }
`

const Underline = motion(styled.div`
  width: 0%;
  height: 0.25rem;
  background-color: rgba(0, 0, 0, 0.5);
`)

const ButtonText = motion(styled(Button)`
  all: unset;
  :hover {
    background: transparent;
  }
`)

const SectionContent = styled.div`
  max-width: calc(100% - 7.5rem);
  margin: 0 auto;
  width: 82rem;
  padding: 5rem 0;
`

const Title = styled(Text)`
  color: rgba(0, 0, 0, 0.5);
  font-family: ${(theme) => theme.secondaryFont};
`

const ImageWrapper = motion(styled.div<{ $odd: boolean }>`
  display: block;
  position: absolute;
  min-height: 300px;
  max-height: 500px;
  min-width: 300px;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
  ${(_, { $odd }) =>
    $odd
      ? css`
          right: calc(100% + 1rem);
        `
      : css`
          left: calc(100% + 1rem);
        `}
  @media ${notDesktop} {
    display: none;
  }
`)

const variants = {
  hover: {
    opacity: 1,
    y: '-50%',
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    y: '-30%',
    transition: {
      duration: 0.3,
    },
  },
}

type WineProps = WineData & { setColor: StateSetter<string>; odd: boolean }

function Wine({ setColor, image, color, id, title, odd }: WineProps) {
  const { show } = useParamModal(WineModal.param)

  return (
    <AnimatePresence>
      <ButtonText
        kind="transparent"
        onMouseOver={() => setColor(color ?? '#DACF99')}
        whileHover="hover"
        initial="hidden"
        onClick={() => show(id)}
      >
        <WineTitle tag="span" as="h1" key={title}>
          {title}
          <Underline
            variants={{
              hover: {
                width: '100%',
              },
              initial: {
                width: '0%',
              },
            }}
          />

          {image ? (
            <AssetsProvider path={`/wine/${id}`}>
              <ImageWrapper variants={variants} tabIndex={-1} $odd={odd}>
                <Image img={image[0]} aspectRatio />
              </ImageWrapper>
            </AssetsProvider>
          ) : null}
        </WineTitle>
      </ButtonText>
    </AnimatePresence>
  )
}

export function WineIndex(): ReactElement | null {
  const [color, setColor] = useState('#DACF99')
  const { data } = useWines()

  if (!data) {
    return null
  }
  return (
    <WineSection $color={color} id="wina">
      <FadeIn>
        <SectionContent>
          <Wrap>
            <Title tag="span">NASZE WINA</Title>
            <Stack kind="vertical" spacing="extraSmall">
              {data.map((wine, idx) => (
                <Wine
                  {...wine}
                  setColor={setColor}
                  key={wine.id}
                  odd={Boolean(idx % 2)}
                />
              ))}
            </Stack>
          </Wrap>
        </SectionContent>
      </FadeIn>
    </WineSection>
  )
}
