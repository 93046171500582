import { mobile, notDesktop, styled, Text, useParamModal } from '@obvio/app'
import { SvgLogoLong, SvgMenu, SvgSmallLogo } from '@obvio/svg'
import { FadeIn, useUi } from '@obvio/template'
import { Button, Grid, GridItem, Stack } from '@obvio/ui'
import { useState } from 'react'

import { ContactModal } from './Modals/ContactModal'

import type { ReactElement } from 'react'

const NavElement = styled.nav`
  height: var(--nav-height);
  position: sticky;
  top: 0;
  z-index: 3;
  margin: 0 auto;
  width: 100%;
  background-color: ${(theme) => theme.background};
  @media ${mobile} {
    max-width: 100%;
  }
`

const LogoButton = styled(Button)`
  min-width: 0;
  padding: 0;
  width: auto;
`

const NavContent = styled(Grid)`
  align-items: center;
  margin: 0 auto;
  width: 82rem;
  max-width: calc(100% - 7.5rem);
  height: 100%;
  @media ${mobile} {
    max-width: calc(100% - 5rem);
  }
`

const Links = styled(Stack)`
  align-items: center;
  button {
    padding: 0;
    border: none;
    ${(theme) => theme.links.css}
    :hover {
      background: transparent;
    }
  }
  @media ${notDesktop} {
    > a {
      display: none;
    }
    > button:nth-child(2) {
      display: none;
    }
  }
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    padding: 0;
    min-width: 0;
  }
`

const LogoText = styled(Text)`
  font-size: 1.875rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 500;
`

const StyledFadeIn = styled(FadeIn)`
  position: sticky;
  top: 0;
  z-index: 3;
  margin: 0 auto;
  width: 100%;
`

type NavProps = {
  className?: string
  customIcon?: ReactElement
}

// TODO: Create TEMPLATE
export function Nav({ className, customIcon }: NavProps): ReactElement {
  const [, dispatch] = useUi('menuPanelOpen')
  const { show } = useParamModal(ContactModal.param)
  const [hover, setHover] = useState(false)
  return (
    <StyledFadeIn className={className}>
      <NavElement>
        <NavContent templateColumns="repeat(3, minmax(0,1fr))">
          <Links>
            <LogoButton
              kind="icon"
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
            >
              {hover ? <SvgLogoLong /> : <SvgSmallLogo />}
            </LogoButton>
            {hover ? null : (
              <Button onClick={() => show('true')}>UMÓW WINE TOUR</Button>
            )}
          </Links>
          <GridItem>
            <LogoText tag="span">WINNICA</LogoText>
          </GridItem>
          <ButtonWrap>
            {customIcon ?? (
              <Button
                kind={['icon', 'transparent']}
                onClick={() => dispatch('TOGGLE_MENU_PANEL')}
              >
                <SvgMenu />
              </Button>
            )}
          </ButtonWrap>
        </NavContent>
      </NavElement>
    </StyledFadeIn>
  )
}
