import { notDesktop, styled } from '@obvio/app'
import { Accordion as AccordionUi } from '@obvio/ui'

import type { BasicShades, Color } from '@obvio/utils'

const Element = styled(AccordionUi.Element)`
  border-bottom: thin solid
    ${(theme) => (theme.colors.additional[2] as Color<BasicShades>).deep} !important;
  color: ${(theme) =>
    (theme.colors.additional[2] as Color<BasicShades>).deep} !important;
  svg {
    width: 0.75rem !important;
    height: 0.75rem !important;
  }
  > div {
    padding: ${(theme) => theme.spacing.small} 0;
  }
`

const Accordion = AccordionUi

Accordion.Element = Element

const AccordionTitle = styled.span`
  font-family: ${(theme) => theme.secondaryFont};
  font-size: 1.375rem;
  padding-left: ${(theme) => theme.spacing.extraSmall};
  color: ${(theme) =>
    (theme.colors.additional[2] as Color<BasicShades>).deep} !important;
  @media ${notDesktop} {
    padding-left: 0;
  }
`

const AccordionHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${(theme) => theme.spacing.extraSmall};
  border-bottom: thin solid
    ${(theme) => (theme.colors.additional[2] as Color<BasicShades>).deep} !important;
  align-items: center;
  width: 100%;
  color: ${(theme) =>
    (theme.colors.additional[2] as Color<BasicShades>).deep} !important;
  svg {
    width: 0.75rem !important;
    height: 0.75rem !important;
  }
`

export { Accordion, AccordionTitle, AccordionHead }
