import {
  useParamModal,
  Text,
  styled,
  notDesktop,
  useMutation,
} from '@obvio/app'
import { SvgCloseMenu } from '@obvio/svg'
import {
  Button,
  CheckboxInput,
  Form,
  FormPage,
  Grid,
  Stack,
  Submit,
  TextArea,
  TextInput,
} from '@obvio/ui'

import { StyledModal } from './components'
import { Nav } from '../Nav'
import { PHONE_NUMBER } from '@/constants'

import type { ReactElement } from 'react'

const FormActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const StyledNav = styled(Nav)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  > nav {
    background: #d6ce95;
  }
`

const PatternWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 6rem;
  overflow: hidden;
  @media ${notDesktop} {
    position: relative;
    margin-top: ${(theme) => theme.spacing.large};
  }
`
const Pattern = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/static/images/pattern.png');
  background-repeat: repeat;
  background-size: contain;
  transform: scale(4.5);
`

const Wrap = styled(Grid)`
  width: 100%;
  > div:first-child {
    text-align: left;
    max-width: 20rem;
  }
  @media ${notDesktop} {
    grid-template-columns: 1fr;
    max-width: calc(100% - 5rem);
    margin: 0 auto;
  }
`
const Modal = styled(StyledModal)`
  section {
    width: 82rem;
    max-width: calc(100% - 7.5rem);
    height: 100%;
    p {
      margin-left: 0 !important;
    }
  }
  @media ${notDesktop} {
    section {
      display: block;
      padding-top: var(--nav-height);
      max-width: 100%;
      width: 100%;
    }
  }
`

const InfoStack = styled(Stack)`
  & > p {
    margin-left: 0 !important;
    text-align: left;
    font-size: 1.5rem;
    font-family: ${(theme) => theme.font};
  }
`

const Title = styled(Text)`
  @media ${notDesktop} {
    font-size: 2rem !important;
    line-height: 120%;
  }
`

const MODAL_PARAM = 'contact-modal'

export function ContactModal(): ReactElement | null {
  const { data, hide } = useParamModal(MODAL_PARAM)
  const [sendMail] = useMutation('sendContactMail')

  const handleSubmit = async (formData: any) => {
    void sendMail(formData)
  }

  return (
    <Modal open={Boolean(data)}>
      <Wrap templateColumns="1fr 1fr" gap="large">
        <InfoStack kind="vertical" spacing="small">
          <Title as="h1">Zapraszamy do kontaktu</Title>
          <Text>Winnica Białe Skały</Text>
          <Text>
            ul. Bonerów 33,
            <br />
            42-440 Podzamcze
            <br />
            20°28′E, 55°37′N
          </Text>
          <Text>
            {PHONE_NUMBER}
            <br />
            kontakt@bialeskaly.pl
          </Text>
        </InfoStack>
        <Stack kind="vertical" fullWidth>
          <Form contextOnly onSubmit={handleSubmit}>
            <FormPage>
              <TextInput placeholder="IMIĘ I NAZWISKO" name="name" />
              <TextInput placeholder="EMAIL" name="email" />
              <TextInput placeholder="NUMER TELEFONU" name="phone" />
              <TextArea placeholder="WIADOMOŚĆ" name="message" rows={5} />
              <FormActions>
                <CheckboxInput
                  label="Akceptuję politykę prywatności"
                  name="rodo"
                  noSubmit
                  optional={false}
                />
                <Submit>Wyślij</Submit>
              </FormActions>
            </FormPage>
            <FormPage successPage>Dziękujemy za wiadomość!</FormPage>
          </Form>
        </Stack>
      </Wrap>
      <StyledNav
        customIcon={
          <Button kind={['icon', 'transparent']} onClick={hide}>
            <SvgCloseMenu />
          </Button>
        }
      />
      <PatternWrap>
        <Pattern />
      </PatternWrap>
    </Modal>
  )
}

ContactModal.param = MODAL_PARAM
