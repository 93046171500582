import { Link, notDesktop, styled, Text, useMutation } from '@obvio/app'
import { SvgLogoLong } from '@obvio/svg'
import {
  Button,
  CheckboxInput,
  Form,
  Grid,
  Stack,
  Submit,
  TextInput,
} from '@obvio/ui'

import { PHONE_NUMBER } from '@/constants'

import type { ReactElement } from 'react'

const FooterWrap = styled.footer`
  background-color: ${(theme) => theme.colors.additional[2]};
  height: 50rem;
  display: flex;
  flex-direction: column;
  @media ${notDesktop} {
    min-height: 100vh;
    height: auto;
  }
`

const Content = styled(Grid)`
  max-width: calc(100% - 7.5rem);
  padding: 5rem 0;
  margin: 0 auto;
  width: 82rem;
  flex: 1;
  p {
    font-family: ${(theme) => theme.secondaryFont};
  }
  @media ${notDesktop} {
    grid-template-columns: 1fr;
    max-width: calc(100% - 5rem);
  }
  > div {
    overflow: hidden;
  }
`

const EndElement = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  @media ${notDesktop} {
    display: none;
  }
`

const EndElementMobile = styled.div`
  display: none;
  width: 100%;
  @media ${notDesktop} {
    display: block;
    margin-top: ${(theme) => theme.spacing.large};
    > svg {
      width: 100% !important;
    }
  }
`

const SignContent = styled(Stack)`
  flex: 1;
`

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const LinksWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  span {
    font-family: ${(theme) => theme.secondaryFont};
  }
  @media ${notDesktop} {
    display: grid;
    grid-template-columns: 1fr;
    gap: ${(theme) => theme.spacing.small};
    margin-top: ${(theme) => theme.spacing.large};
  }
  button {
    height: auto;
    min-height: 0;
    min-width: 0;
    width: auto;
    padding: 0;
    border: none;
    :hover {
      background: transparent;
    }
  }
`

const PatternWrap = styled.div`
  width: 100%;
  height: 11rem;
  overflow: hidden;
`
const Pattern = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/static/images/pattern.png');
  background-repeat: repeat;
  background-size: contain;
  transform: scale(2.5);
`

export function Footer(): ReactElement {
  const [send] = useMutation('sendNewsletterMail')
  return (
    <FooterWrap>
      <Content templateColumns="1fr 1fr" gap="large">
        <Panel>
          <Stack kind="vertical">
            <Text>WINNICA BIAŁE SKAŁY</Text>
            <Text>
              {PHONE_NUMBER}
              <br /> kontakt@bialeskaly.pl
            </Text>
            <Text>
              ul. Bonerów 33,
              <br />
              42-440 Podzamcze
              <br />
              k. Ogrodzieńca
            </Text>
            <Text>
              1920°5528′E
              <br />
              505°4537′N
            </Text>
          </Stack>
          <EndElement>
            <SvgLogoLong />
          </EndElement>
        </Panel>
        <Panel>
          <SignContent kind="vertical">
            <Text tag="span" as="h1">
              Zapisz się do newslettera i otrzymuj najświeższe informacje z
              życia winnicy
            </Text>
            <Form
              contextOnly
              onSubmit={(data) => {
                void send(data)
              }}
            >
              <TextInput
                placeholder="Wpisz adres e-mail"
                name="email"
                suffix={<Submit kind={['icon', 'transparent']}>Wyślij</Submit>}
              />
              <CheckboxInput
                label="Akceptuję politykę prywatności"
                name="rodo"
                noSubmit
              />
            </Form>
          </SignContent>
          <LinksWrap>
            <Stack>
              <Link href="https://www.facebook.com/profile.php?id=100092365662105">
                Facebook
              </Link>
              <Link href="https://www.instagram.com/bialeskaly/">
                Instagram
              </Link>
            </Stack>
            <Button onClick={() => window.scrollTo(0, 0)}>
              <Text tag="span">Wróć na górę strony</Text>
            </Button>
          </LinksWrap>
          <EndElementMobile>
            <SvgLogoLong />
          </EndElementMobile>
        </Panel>
      </Content>
      <PatternWrap>
        <Pattern />
      </PatternWrap>
    </FooterWrap>
  )
}
