import { useQuery } from '@obvio/app'

import type { Schema } from '@obvio/app'
import type { DeepPick } from '@obvio/utils'

export const STRAIN_SELECT = {
  id: true,
  title: true,
  description: true,
} as const

export type StrainData = DeepPick<Schema['Strain'], typeof STRAIN_SELECT>

type query = typeof useQuery<
  'getStrains',
  typeof STRAIN_SELECT,
  { select: typeof STRAIN_SELECT }
>

export function useStrains(): ReturnType<query> {
  return useQuery('getStrains', { select: STRAIN_SELECT })
}
