import { styled, notDesktop } from '@obvio/app'
import { Button, Grid, Stack } from '@obvio/ui'

export const ImageWrap = styled.div`
  height: 100%;
  width: 100%;
  @media ${notDesktop} {
    height: 25rem;
    margin-bottom: ${(theme) => theme.spacing.small};
    margin-top: ${(theme) => theme.spacing.large};
  }
`

export const Content = styled(Grid)<{ $color: string }>`
  min-height: 100%;
  background-color: ${(_, { $color }) => $color};
  transition: background-color 0.3s ease;
  * {
    color: rgba(0, 0, 0, 0.5) !important;
  }
  a {
    border-color: rgba(0, 0, 0, 0.5) !important;
  }
  @media ${notDesktop} {
    grid-template-columns: 1fr;
    padding: ${(theme) => theme.spacing.small};
  }
`

export const Info = styled(Stack)`
  margin: auto;
  max-width: 60%;
  text-align: center;
  @media ${notDesktop} {
    max-width: 100%;
  }
`

export const Divider = styled.div`
  height: 1px;
  width: 20%;
  margin-left: auto !important;
  margin-right: auto !important;
  background-color: rgba(0, 0, 0, 0.5);
`

export const NavigationWrap = styled.div`
  display: none;
  justify-content: space-between;
  width: 100%;
  span {
    font-family: ${(theme) => theme.secondaryFont};
  }
  grid-row: 3;
  @media ${notDesktop} {
    display: flex;
  }
`

export const ArrowButton = styled(Button)`
  padding: 0;
  height: auto;
  min-width: auto;
  > div {
    color: black;
    > div {
      background: black;
    }
  }
`
