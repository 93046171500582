import { styled, Text } from '@obvio/app'
import { Stack } from '@obvio/ui'
import {
  motion,
  useMotionValue,
  useTransform,
  useAnimationFrame,
} from 'framer-motion'

import { useStrains } from '@/utils/hooks/useStrains'

import type { ReactElement } from 'react'

const Parallax = styled.div`
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
`

const Scroller = motion(styled.div`
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  > span {
    margin-right: 2rem;
  }
`)

type ParallaxProps = {
  children: string
  baseVelocity: number
}

// @https://github.com/motiondivision/motionone/blob/main/packages/utils/src/wrap.ts thanks motionone
const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}

function ParallaxText({ children, baseVelocity }: ParallaxProps) {
  const baseX = useMotionValue(0)
  const x = useTransform(baseX, (v) => `${wrap(-20, -70, v)}%`)

  useAnimationFrame((_t, delta) => {
    baseX.set(baseX.get() + baseVelocity * (delta / 1000))
  })

  return (
    <Parallax>
      <Scroller style={{ x }}>
        <Text tag="span" as="h2">
          {children}
        </Text>
        <Text tag="span" as="h2">
          {children}
        </Text>
      </Scroller>
    </Parallax>
  )
}

export function WineList(): ReactElement | null {
  const { data } = useStrains()

  if (!data) {
    return null
  }
  const text = data.map((strain) => strain.title).join(' - ')
  return (
    <Stack kind="vertical">
      <ParallaxText baseVelocity={1}>{text}</ParallaxText>
      <ParallaxText baseVelocity={-1}>{text}</ParallaxText>
    </Stack>
  )
}
