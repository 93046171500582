import { useQuery } from '@obvio/app'

import type { Schema } from '@obvio/app'
import type { DeepPick } from '@obvio/utils'

export const WINE_SELECT = {
  id: true,
  title: true,
  image: true,
  description: true,
  features: true,
  url: true,
  color: true,
} as const

export type WineData = DeepPick<Schema['Wine'], typeof WINE_SELECT>

type query = typeof useQuery<
  'getWines',
  typeof WINE_SELECT,
  { select: typeof WINE_SELECT }
>

export function useWines(): ReturnType<query> {
  return useQuery('getWines', { select: WINE_SELECT })
}
