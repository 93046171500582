import { Image, useParamModal, Text, AssetsProvider } from '@obvio/app'
import { RichText } from '@obvio/template'
import { Button, Modal, Stack } from '@obvio/ui'

import { ContactModal } from './ContactModal'
import {
  ArrowButton,
  Content,
  Divider,
  ImageWrap,
  Info,
  NavigationWrap,
} from './WineModal/components'
import { Arrow } from '../Arrow'
import { useWines } from '@/utils/hooks/useWines'

import type { ReactElement } from 'react'

const MODAL_PARAM = 'wine-modal'

export function WineModal(): ReactElement | null {
  const { data, hide, show } = useParamModal(MODAL_PARAM)
  const { show: showContact } = useParamModal(ContactModal.param)
  const { data: wineData } = useWines()

  const selectedWineIdx = (wineData || [])?.findIndex(
    (wine) => wine.id === data,
  )

  if (selectedWineIdx < 0 || !wineData) {
    return null
  }

  const selectedWine = wineData[selectedWineIdx]
  const numOfWines = wineData.length

  const openContact = async () => {
    await showContact('true')
  }

  return (
    <AssetsProvider path={`/wine/${selectedWine.id}`}>
      <Modal onClose={hide} open={Boolean(data)}>
        <Content
          templateColumns="1fr 1fr"
          $color={selectedWine.color ?? '#DACF99'}
        >
          <ImageWrap>
            <Image img={selectedWine.image[0]} />
          </ImageWrap>
          <Info kind="vertical" spacing="large">
            <NavigationWrap>
              <Stack>
                <Text tag="span">
                  {selectedWineIdx + 1} / {numOfWines}
                </Text>
              </Stack>
              <Stack>
                <ArrowButton
                  kind="icon"
                  onClick={() => {
                    const nextIdx =
                      selectedWineIdx === 0
                        ? numOfWines - 1
                        : selectedWineIdx - 1

                    void show(wineData[nextIdx]?.id)
                  }}
                >
                  <Arrow direction="left" length="3rem" />
                </ArrowButton>
                <ArrowButton
                  kind="icon"
                  onClick={() =>
                    show(wineData[(selectedWineIdx + 1) % numOfWines]?.id)
                  }
                >
                  <Arrow direction="right" length="3rem" />
                </ArrowButton>
              </Stack>
            </NavigationWrap>
            <Text tag="h3" as="sub-h2">
              {selectedWine.title}
            </Text>
            <div>
              <RichText value={selectedWine.description ?? ''} />
            </div>
            <Divider />
            <div>
              <RichText value={selectedWine.features ?? ''} />
            </div>
            {/**
							selectedWine.url ? (
              <Button href={selectedWine.url}>SKLEP ONLINE</Button>
            ) : null
						*/}
            <Button onClick={openContact}>KONTAKT</Button>
          </Info>
        </Content>
      </Modal>
    </AssetsProvider>
  )
}

WineModal.param = MODAL_PARAM
