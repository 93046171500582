import { useQuery } from '@obvio/app'

import type { Schema } from '@obvio/app'
import type { DeepPick } from '@obvio/utils'

export const EVENT_SELECT = {
  id: true,
  date: true,
  title: true,
  images: true,
  description: true,
  slug: true,
} as const

export type WineData = DeepPick<Schema['Event'], typeof EVENT_SELECT>

type query = typeof useQuery<
  'getEvents',
  typeof EVENT_SELECT,
  { select: typeof EVENT_SELECT }
>

export function useEvents(): ReturnType<query> {
  return useQuery('getEvents', {
    select: EVENT_SELECT,
    args: {
      byDate: {
        // see ya in 1k years
        lte: new Date('3000'),
      },
    },
  })
}
